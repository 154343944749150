import { TermItemDefinition } from '@/services/term'
import { defineStore } from 'pinia'
export const useSearchSubsidyGroupingStore = defineStore('searchSubsidyGroupingStore', {
  state: () => ({
    groupByCreditor: null as string | null,
    groupByDate: null as string | null,
    groupByOrder: null as string | null,
    groupByRun: null as string | null,
    groupByInvoice: null as string | null,
    groupByAccount: null as string | null,
    groupByLocalUnit: null as string | null,
    items: [
      { key: 'groupByCreditor', operation: ':' },
      { key: 'groupByDate', operation: ':' },
      { key: 'groupByOrder', operation: ':' },
      { key: 'groupByRun', operation: ':' },
      { key: 'groupByInvoice', operation: ':' },
      { key: 'groupByAccount', operation: ':' },
      { key: 'groupByLocalUnit', operation: ':' }
    ] as TermItemDefinition[]
  })
})
