<template>
  <div>
    <h2 v-html="$t('search_subsidy_grouping_title')"></h2>
    <!-- Gesuchsteller einzeln -->
    <v-switch
      id="search_subsidy_grouping_creditor"
      v-model="searchCardStore.groupByCreditor"
      color="primary"
      :label="$t('search_subsidy_grouping_creditor')"
      @keyup.enter="apply()"
    ></v-switch>
    <!-- Abschlussmonate einzeln -->
    <v-switch
      id="search_subsidy_grouping_date"
      v-model="searchCardStore.groupByDate"
      color="primary"
      :label="$t('search_subsidy_grouping_date')"
      @keyup.enter="apply()"
    ></v-switch>
    <!-- Order-ID einzeln -->
    <v-switch
      id="search_subsidy_grouping_order"
      v-model="searchCardStore.groupByOrder"
      color="primary"
      :label="$t('search_subsidy_grouping_order')"
      @keyup.enter="apply()"
    ></v-switch>
    <!-- Übermittlungslauf einzeln -->
    <v-switch
      id="search_subsidy_grouping_run"
      v-model="searchCardStore.groupByRun"
      color="primary"
      :label="$t('search_subsidy_grouping_run')"
      @keyup.enter="apply()"
    ></v-switch>
    <!-- Abrechnung einzeln -->
    <v-switch
      id="search_subsidy_grouping_invoice"
      v-model="searchCardStore.groupByInvoice"
      color="primary"
      :label="$t('search_subsidy_grouping_invoice')"
      @keyup.enter="apply()"
    ></v-switch>
    <!-- GL-Konto einzeln -->
    <v-switch
      id="search_subsidy_grouping_account"
      v-model="searchCardStore.groupByAccount"
      color="primary"
      :label="$t('search_subsidy_grouping_account')"
      @keyup.enter="apply()"
    ></v-switch>
    <!-- Betrieb einzeln -->
    <v-switch
      id="search_subsidy_grouping_local_unit"
      v-model="searchCardStore.groupByLocalUnit"
      color="primary"
      :label="$t('search_subsidy_grouping_local_unit')"
      @keyup.enter="apply()"
    ></v-switch>
  </div>
</template>

<script lang="ts">
import baseSearch from '@/components/searchCards/baseSearch.vue'
import { useSearchSubsidyGroupingStore } from '@/store/SearchSubsidyGroupingStore'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchSubsidyGrouping',
  extends: baseSearch,
  data() {
    return {}
  },
  methods: {
    apply() {
      this.search()
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchSubsidyGroupingStore(), true)
  }
})
</script>
